<template>
  <div>
    <map-chart
      :country-data="{'US': 4, 'CA': 7, 'GB': 8, 'IE': 14, 'ES': 21}"
      high-color="#ff0000"
      low-color="#aaaaaa"
      country-stroke-color="#909090"
      default-country-fill-color="#dadada"
      legend-font-color-content="#ff0000"
    />

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <base-card>
          <v-card-text>
            <map-chart
              :country-data="{'US': 4, 'CA': 7, 'GB': 8, 'IE': 14, 'ES': 21}"
              high-color="#ff0000"
              low-color="#aaaaaa"
              country-stroke-color="#909090"
              default-country-fill-color="#dadada"
              legend-font-color-content="#ff0000"
            />
          </v-card-text>
        </base-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import MapChart from 'vue-map-chart'
  export default {
    components: {
      MapChart,
    },
    data () {
      return {

      }
    },
  }
</script>
<style lang="stylus">

</style>
